<!-- INFO: component with custom css -->
<nz-layout class="app-layout">
  <!-- always show header on desktop -->
  @if (isDesktop || showHeader) {
  <nz-header class="header">
    <app-header></app-header>
  </nz-header>
  }
  <nz-layout class="layout">
    <!-- on mobile, we show the navigation if there's no header -->
    @if (!showHeader && !isDesktop) {
    <app-top-navigation [showBackButton]="true" [title]="navTitle"></app-top-navigation>
    }
    <!-- on desktop, we always show the navigation; remove the back button if in sevices category or thank you page -->
    @if (isDesktop) {
    <app-top-navigation [showBackButton]="!showHeader" [title]="navTitle"></app-top-navigation>
    }

    <nz-layout class="justify-center">
      <!-- main content -->
      <nz-content class="p-4 md:p-6 md:mx-4 md:rounded-md2 content">
        <router-outlet></router-outlet>
      </nz-content>

      <!-- cart area -->
      @if (!hideSider){
      <nz-sider nzWidth="350px" class="mr-4 border-solid" nzBreakpoint="lg" [nzCollapsedWidth]="0">
        <app-slider-right></app-slider-right>
      </nz-sider>
      }
    </nz-layout>
  </nz-layout>
  <!-- <nz-footer style="text-align: center">GlamBooking Widget 2024</nz-footer> -->
</nz-layout>
