import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
          // #TODO handle if applicable
          case 500:
            // #TODO show snackbar (Internal Server Error)
            break;
          case 404:
            // #TODO show snackbar (Resource Not Found)
            break;
          case 403:
            // #TODO show snackbar (Forbidden Action)
            break;
        }

        // pass the error on to the next layer
        return throwError(() => error);
      }),
    );
  }
}
