<div class="w-full full-height">
  <app-layout></app-layout>
  @if (loading$ | async) {
  <div
    style="
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1001;
      background: rgba(24, 144, 255, 0.1);
    "
  >
    <div style="position: absolute; top: 50%; left: 50%; margin: -16px 0 0 -16px">
      <nz-spin nzSize="large"></nz-spin>
    </div>
  </div>
  }
</div>
