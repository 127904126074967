<!-- INFO: component with custom css -->
<div class="top-navigation" nz-flex nzAlign="center">
  <button
    *ngIf="showBackButton"
    nz-button
    nzSize="large"
    class="pl-4 md:pl-0 pr-3" nzType="link"
    (click)="onGoBack()"
    nz-icon
    nzType="arrow-left"
    nzTheme="outline"
  ></button>
  <div class="title-container w-full" nz-flex>
    <h2 nz-typography>{{ title }}</h2>
  </div>
</div>
