import { Routes } from '@angular/router';
import { servicesResolver } from './features/shared/services.resolver';
import { accountResolver } from './features/shared/account.resolver';
import { appointmentResolver } from './features/shared/appointment.resolver';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/services' },
  {
    path: 'services',
    resolve: { servicesData: servicesResolver, appointmentData: appointmentResolver },
    loadChildren: () => import('./features/appointment/appointment.routes').then((m) => m.APPOINTMENT_ROUTES),
  },

  {
    path: 'toolkit',
    loadChildren: () => import('./features/toolkit/toolkit.routes').then((m) => m.TOOLKIT_ROUTES),
  },
  { path: '**', redirectTo: '/services' },
];
