import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import {
  NzContentComponent,
  NzFooterComponent,
  NzHeaderComponent,
  NzLayoutComponent,
  NzSiderComponent,
} from 'ng-zorro-antd/layout';
import { HeaderComponent } from '../header/header.component';
import { TopNavigationComponent } from '../layout/top-navigation/top-navigation.component';
import { ServicesListComponent } from '../appointment/services-list/services-list.component';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SubscribedComponent } from 'src/app/lib/subscribed.component';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { SettingInterface, ThemeService } from '@services/theme.service';
import { WindowService } from '@services/window.service';
import { ThemeOptionsKey } from 'src/app/config/constants';
import { buffer, filter, map, takeUntil } from 'rxjs';
import { SliderRightComponent } from './slider-right/slider-right.component';
import { CommonModule } from '@angular/common';
import { AccountService } from '@services/account.service';
import { Account } from 'src/app/models/account.model';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NzLayoutComponent,
    NzHeaderComponent,
    NzContentComponent,
    NzFooterComponent,
    NzSiderComponent,
    HeaderComponent,
    ServicesListComponent,
    RouterOutlet,
    SliderRightComponent,
    TopNavigationComponent,
    CommonModule,
  ],
})
export class LayoutComponent extends SubscribedComponent implements OnInit {
  private themesOptions: SettingInterface;
  account: Account;
  showHeader: boolean = true;
  hideSider: boolean;
  isDesktop: boolean = false;
  navTitle: string = '';

  constructor(
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private nzConfigService: NzConfigService,
    private themesService: ThemeService,
    private windowService: WindowService,
    private accountService: AccountService
  ) {
    super();
    this.checkDesktop(window.innerWidth);

    // Theme colors
    this.themesService
      .getThemesMode()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((mode) => {
        this.themesOptions = mode;
        let primaryColor = this.themesOptions.color;
        this.navTitle = this.themesOptions.navTitle;
        this.nzConfigService.set('theme', { primaryColor });
        setTimeout(() => this.ref.markForCheck(), 0);
      });

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params['color']) {
        this.changePrimaryColor(params['color']);
      }

      if (params['theme']) {
        const primaryColor = this.themesService.getPredefinedThemeColor(params['theme']);
        if (primaryColor) {
          this.changePrimaryColor(primaryColor);
        }
      }
    });

    // Show/hide layout components
    const routeEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));

    this.router.events
      .pipe(
        filter((e) => e instanceof ChildActivationEnd),
        buffer(routeEndEvent$),
        map(([ev]) => (ev as ChildActivationEnd).snapshot.firstChild.data),
        takeUntil(this.destroyed$)
      )
      .subscribe((childRoute) => {
        this.showHeader = childRoute['showHeader'] as boolean;
        this.hideSider = childRoute['hideSider'] as boolean;
        this.ref.markForCheck();
      });
  }

  ngOnInit() {
    this.accountService.accountChanged.pipe(takeUntil(this.destroyed$)).subscribe((account) => {
      this.account = account;
      if (this.account.themeColor) {
        this.changePrimaryColor(this.account.themeColor);
      }
      this.ref.markForCheck();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkDesktop(event.target.innerWidth);
  }

  private checkDesktop(width: number) {
    this.isDesktop = width >= 768;
    this.ref.markForCheck();
  }

  changePrimaryColor(primaryColor: string): void {
    this.nzConfigService.set('theme', { primaryColor });
    this.themesOptions.color = primaryColor;
    this.setThemeOptions();
  }

  setThemeOptions(): void {
    this.themesService.setThemesMode(this.themesOptions);
    this.windowService.setStorage(ThemeOptionsKey, JSON.stringify(this.themesOptions));
  }
}
