import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { LayoutComponent } from './features/layout/layout.component';
import { SpinService } from '@services/spin.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { SubscribedComponent } from './lib/subscribed.component';
import { PreloaderService } from '@services/preloader.service';
// import { Store } from '@ngrx/store';
// import { init } from './store/counter.actions';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, NzSpinModule, NzLayoutModule, LayoutComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent extends SubscribedComponent implements OnInit, AfterViewInit {
  title = 'widget';
  loading$ = this.spinService.getCurrentGlobalSpinStore();

  // constructor(private store: Store) {}
  constructor(private router: Router, private spinService: SpinService, private preloader: PreloaderService) {
    super();
  }

  ngOnInit(): void {
    // this.store.dispatch(init());
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: NzSafeAny) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.spinService.setCurrentGlobalSpinStore(false);
      });
  }

  ngAfterViewInit(): void {
    this.preloader.removePreLoader();
  }
}
