import { Component } from '@angular/core';
import { CartContentComponent } from '../../shared/cart-content/cart-content.component';
import { NzTypographyComponent } from 'ng-zorro-antd/typography';
import { NzCardComponent } from 'ng-zorro-antd/card';

@Component({
  selector: 'app-slider-right',
  standalone: true,
  templateUrl: './slider-right.component.html',
  styleUrl: './slider-right.component.less',
  imports: [CartContentComponent, NzTypographyComponent, NzCardComponent],
})
export class SliderRightComponent {}
