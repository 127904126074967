// import { ResolveFn } from '@angular/router';

// export const accountResolver: ResolveFn<boolean> = (route, state) => {
//   console.log('accountResolver', route, state);
//   return true;
// };

import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot, MaybeAsync } from '@angular/router';
import { AccountService } from '@services/account.service';

import { Observable, of } from 'rxjs';
import { Account } from 'src/app/models/account.model';

export const accountResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  accountService: AccountService = inject(AccountService)
): Observable<Account> => {
  const account = accountService.getAccount();
  if (account.id) {
    console.log('accountResolverService - return cached account');
    return of(account);
  } else {
    console.log('accountResolverService - do the http call to fetch account');
    return accountService.fetchAccount();
  }
};
