import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import {
  ArrowLeftOutline,
  ArrowDownOutline,
  CheckCircleOutline,
  DeleteOutline,
  EditOutline,
  LeftOutline,
  MoreOutline,
  RightOutline,
  SearchOutline,
  CalendarOutline,
} from '@ant-design/icons-angular/icons';
import { NzIconModule } from 'ng-zorro-antd/icon';

const icons = [
  ArrowLeftOutline,
  ArrowDownOutline,
  CheckCircleOutline,
  DeleteOutline,
  EditOutline,
  LeftOutline,
  MoreOutline,
  RightOutline,
  SearchOutline,
  CalendarOutline,
];

export function provideNzIcons(): EnvironmentProviders {
  return importProvidersFrom(NzIconModule.forRoot(icons));
}
